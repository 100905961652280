.tick {
  font-size: 15px;
}
.c3-chart-lines path {
  stroke-width: 4px; /* Adjust the line thickness as needed */
}
.c3-circle {
  r: 5; /* Adjust the size of the data points as needed */
}
.c3-bar {
  stroke-width: 5px !important;
}