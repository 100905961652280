.accountPopover {
  position: absolute;
  top: 37px;
  right: 43px;
}

.id-small-text {
  font-size: 11px;
  color: #9e9e9e;
}

.gateway-app-bar-scrollable {
  overflow-x: auto;
  width: calc(100% + 49px);
  margin-left: -24px;
  position: relative;
  box-shadow: inset -10px 0 10px -10px rgba(0, 0, 0, 0.5);

  
  table {
    border-collapse: collapse;
    text-align: center;
    z-index:-1;
    position:relative;


    th, td {
      background-color: #fff;
      color: #000;
      font-size: 0.7rem;
      border: 1px solid rgb(238, 238, 238);
      padding: 8px;
      min-height: 55px;
    }

    th {
      background-color: #f5f8ff;
      font-weight: bold;
    }

    td {
      border-radius: 0;
    }

    td:last-child {
      border-right: 1px solid rgb(238, 238, 238);
    }
  }
  
}

