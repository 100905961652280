.gateway-icon {
  color: white;
  font-weight: bold;
  outline: none;
  border-radius: 5px;
  padding: 5px;

  &:hover {
    box-shadow: 0 1px 6px black;
  }
}

.gateway-icon svg {
  display: block;
  font-size: 24px;
}

.sensor-icon {
  @extend .gateway-icon;
  &.OK {
    background: linear-gradient(135deg, #276c00 0%, #000000 100%);
  }
  &.ALARM {
    background: linear-gradient(135deg, #b59d50 0%, #000000 100%);
  }
  &.ERROR {
    background: linear-gradient(135deg, #b55050 0%, #000000 100%);
  }
  &.yellow {
    background: linear-gradient(135deg, #fff7cdff 0%, #fff7cd 100%);
  }
  &.leaflet-marker-draggable {
    cursor: grab;
    box-shadow: rgba(0, 0, 0, 0.72) 8px 12px 5px 0px;
  }

  .temperature {
    position:absolute;
    bottom: 2px;
    left: 2px;
  }
  .distance {
    position:absolute;
    bottom: 2px;
    right: 2px;
  }
}
.node-marker-label {
  font-style: italic;
}
.node-marker-gps-label {
  font-style: italic;
  // font-size: 0.8em;
  // color: grey;
  // position: absolute;
  // bottom: -3px;
}
.center-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  color: #00ab55;
  text-shadow: -1px -13px 10px 10px black;
  display: block;
  margin-left: -25px;
  margin-top: -50px;
  width: 50px;
  height: 50px;
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

.leaflet-container {
  .leaflet-popup-content-wrapper {
    border-radius: 9px;
  }
  a.leaflet-popup-close-button {
    width: unset;
    height: unset;
    padding: 10px;
    font-size: 30px;
    color: #00ab55;
    font-weight: 300;
  }
}

.legend {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: grey;
  font-size: 0.8em;
}
